<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Reporte de ventas</h5>
			</div>
			<div class="action-btn-wrap">
				<!-- <v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar Excel
				</v-btn> -->
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row dense>
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Fecha</label>
						<el-date-picker
							v-model="search.date_of_entry"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
						></el-date-picker>
					</div>
				</v-col>
				
				<v-col cols="12" xl="3" lg="4" md="12" sm="12">
					<div class="form-group">
						<label class="control-label">Usuario</label>
						<el-select v-model="search.user_id" clearable>
							<el-option
								v-for="user in users"
								:key="user.id"
								:label="user.name"
								:value="user.id"
							></el-option>
						</el-select>
					</div>
				</v-col>
				<v-col cols="12" xl="2" lg="4" md="12" sm="12">
					<el-button
						type="primary"
						icon="el-icon-search"
						class="btn-block mt-5"
						:loading="loading"
						@click="getData"
					></el-button>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12" lg="12">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<td class="text-right">N°</td>
									<td class="text-right">HABITACIÓN</td>
									<td>CLIENTE</td>
									<td>PRODUCTO / SERVICIO</td>
									<!-- <td class="text-center">FECHA/HORA SALIDA</td> -->
									<td class="text-center">USUARIO</td>
									<td class="text-right">TOTAL</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in records" :key="index">
									<td class="text-right">
										{{ index + 1 }}
									</td>
									<td class="text-right">
										{{ row.room }}
									</td>
									<td>{{ row.customer }}</td>
									<td>{{ row.item }}</td>
									<!-- <td class="text-center">{{ row.date_of_exit +' '+ row.time_of_exit }}</td> -->
									<td class="text-center">{{ row.user }}</td>
									<td class="text-right">
										<strong>{{ row.total }}</strong>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="4"></td>
									<td class="text-right"><strong class="font-black title">Total S/</strong></td>
									<td class="text-right title">
										<strong>{{ balance }}</strong>
									</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import dayjs from 'dayjs';
export default {
	data() {
		return {
			resource: 'reports/sales',
			records: [],
			users: [],
			loading: false,
			balance: 0.0,
			search: {
				date_of_entry: dayjs()
					.format('YYYY-MM-DD'),
				user_id: null,
			},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	created() {
		this.getTables();
	},
	methods: {
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.users = response.data.users;
			});
		},
		async getData() {
			this.loading = true;
			await this.$http.post(`/${this.resource}/records`, this.search).then((response) => {
				this.records = response.data.records;
				this.balance = response.data.balance;

				this.loading = false;
			});
		},
		clickDownloadExcel() {
			this.$http({
				url: `/${this.resource}/excel`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');

				fileLink.href = fileURL;
				fileLink.setAttribute('download', `ReporteAlquileres-${new Date().getTime()}.xlsx`);
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},
	},
};
</script>
